import { createStore } from "vuex";

export default createStore({
  state: {
    access_token: "",
    expiry: "",
    search: "",
    strategy_list: "",
    pnl_filter_list: {
      ctcl_user_ids: [],
      back_office_codes: [],
      account_numbers: [],
      process_names: [],
      teams: [],
      calender_type: "date",
      calender_data: null,
      start_date: null,
      end_date: null,
    },
    ind_pnl_filter_list: {
      datetimeThreshold: null,
      ctcl_user_ids: [],
      back_office_codes: [],
      account_numbers: [],
      process_names: [],
      teams: [],
      recalculate: false,
    },
    reconciled_filter_list: {
      start_date: null,
      end_date: null,
      trader_user_id: null,
      strategy_id: null,
      reconciled: null,
      inst: null,
      selected_strategy_list: [],
    },
    reconciled_filter_list_bse: {
      start_date: null,
      end_date: null,
      trader_user_id: null,
      strategy_id: null,
      reconciled: null,
      inst: null,
      process_name: [],
    },
    order_filter_list: {
      start_date: null,
      end_date: null,
      trader_user_id: null,
      strategy_id: null,
      reconciled: null,
      inst: null,
      selected_strategy_list: [],
    },
    trades_filter_list: {
      start_date: null,
      end_date: null,
      trader_user_id: null,
      strategy_id: null,
      reconciled: null,
      inst: null,
      selected_strategy_list: [],
    },
    execution_filter_list: {
      start_date: null,
      end_date: null,
      trader_user_id: null,
      strategy_id: null,
      reconciled: null,
      inst: null,
      selected_strategy_list: [],
    },
    download_recon_filter: {
      date: new Date(),
      account_number: [],
      back_office_code: [],
      processes: [],
      ctcl_user_id: [],
      team: [],
      process_name: [],
    },
  },
  getters: {},
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("access_token")) {
        state.access_token = localStorage.getItem("access_token");
        state.expiry = localStorage.getItem("expiry");
      } else {
        state.access_token = "";
        state.expiry = "";
      }
      state.search = sessionStorage.getItem("search") || "";

      // filter inital state
      // const startDate = new Date();
      // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));

      //  for pnl
      state.pnl_filter_list.start_date = null;
      state.pnl_filter_list.end_date = null;
      state.pnl_filter_list.selected_strategy_list = [];

      // for indpnl
      state.ind_pnl_filter_list = {
        datetimeThreshold: null,
        ctcl_user_ids: [],
        back_office_codes: [],
        account_numbers: [],
        process_names: [],
        teams: [],
        recalculate: false,
      };
    },
    setAccesstoken(state, access_token) {
      state.access_token = access_token;
    },
    setExpiry(state, expiry) {
      state.expiry = expiry;
    },
    setSearchValue(state, searchValue) {
      state.search = searchValue;
      sessionStorage.setItem("search", searchValue);
    },
    setStrategyList(state, strategy_list) {
      state.strategy_list = strategy_list;
    },
    setIndPnlFilter(state, indPnlFilter) {
      state.ind_pnl_filter_list = indPnlFilter;
    },
    setPnlFilter(state, pnlFilter) {
      state.pnl_filter_list = pnlFilter;
    },
    setReconciledFilter(state, reconciledFilter) {
      state.reconciled_filter_list = reconciledFilter;
    },
    setReconciledFilterBse(state, reconciledFilter) {
      state.reconciled_filter_list_bse = reconciledFilter;
    },
    setOrdersFilter(state, orderFilterList) {
      state.order_filter_list = orderFilterList;
    },
    setTradesFilter(state, tradeFilterList) {
      state.trades_filter_list = tradeFilterList;
    },
    setExcutionFilter(state, execution_filter_list) {
      state.execution_filter_list = execution_filter_list;
    },
    setDownloadReconFilter(state, downloadReconFilter) {
      state.download_recon_filter = downloadReconFilter;
    },
  },
  actions: {},
  modules: {},
});
